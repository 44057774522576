/**
  Angular core components declaration
**/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/**
  Customer Portal Components declaration
**/

import { FilePreviewOverlayComponent } from './spinner/spinner.component';
import { MatProgressSpinnerModule, MatButtonModule, MatDialogModule } from '@angular/material';

@NgModule({
  entryComponents: [
    FilePreviewOverlayComponent
  ],
  declarations: [
    FilePreviewOverlayComponent
  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [
    FilePreviewOverlayComponent,
    MatDialogModule

  ]
})

export class SpinnerOverlayModule {
  constructor() {
  }
}
